// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---docs-download-mdx": () => import("./../../../../docs/download.mdx" /* webpackChunkName: "component---docs-download-mdx" */),
  "component---docs-home-mdx": () => import("./../../../../docs/home.mdx" /* webpackChunkName: "component---docs-home-mdx" */),
  "component---docs-linux-mdx": () => import("./../../../../docs/linux.mdx" /* webpackChunkName: "component---docs-linux-mdx" */),
  "component---docs-windows-mdx": () => import("./../../../../docs/windows.mdx" /* webpackChunkName: "component---docs-windows-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

