module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/app/node_modules/gatsby-theme-docz/src/base/Layout.js"},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/app/.docz"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{},"src":"./","gatsbyRoot":null,"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{},"menu":["Home","Windows","Linux","Download"],"mdPlugins":[],"hastPlugins":[],"ignore":[],"typescript":false,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":null,"o":null,"open":null,"open-browser":null,"root":"/app/.docz","base":"/","source":"./","gatsby-root":null,"files":"**/*.{md,markdown,mdx}","public":"/public","dest":".docz/dist","d":".docz/dist","editBranch":"master","eb":"master","edit-branch":"master","config":"","title":"Cmr Protected","description":"Download e instruções de como instalar o CMR Protected.","host":"localhost","port":3000,"p":3000,"separator":"-","paths":{"root":"/app","templates":"/app/node_modules/docz-core/dist/templates","docz":"/app/.docz","cache":"/app/.docz/.cache","app":"/app/.docz/app","appPackageJson":"/app/package.json","appTsConfig":"/app/tsconfig.json","gatsbyConfig":"/app/gatsby-config.js","gatsbyBrowser":"/app/gatsby-browser.js","gatsbyNode":"/app/gatsby-node.js","gatsbySSR":"/app/gatsby-ssr.js","importsJs":"/app/.docz/app/imports.js","rootJs":"/app/.docz/app/root.jsx","indexJs":"/app/.docz/app/index.jsx","indexHtml":"/app/.docz/app/index.html","db":"/app/.docz/app/db.json"},"htmlContext":{"favicon":"favicon.ico"}},
    }]
